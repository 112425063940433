import React from "react";
import contentCreator from "../assets/images/content-creator.webp";
import contentCreator2 from "../assets/images/content-creator-2.webp";
import { Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Page from "../components/Page";

const AffiliatesPage = () => {
  const { t } = useTranslation();
  return (
    <Page
      title="Lanchas la Gaviota's affiliate marketing program"
      description="Welcome to our affiliate marketing programs. The Gaviotas Associates Program helps content creators, publishers and bloggers monetize their traffic. Associates use easy link-building tools to direct their audience to their recommendations, and earn from qualifying purchases."
    >
      <Container>
        <section className="section" id="affiliates">
          <div className="content-creator">
            <img
              src={contentCreator}
              alt="Content creator"
              className="img-fluid"
              loading="lazy"
            />
            <div className="title">{t("affiliates.title")}</div>
            <div className="call-to-action">
              <a
                className="btn btn-primary"
                href="https://app.lanchaslagaviota.com"
              >
                {t("affiliates.signup")}
              </a>
            </div>
          </div>

          <h3 className="my-5">{t("affiliates.subtitle")}</h3>

          <div className="mb-5">{t("affiliates.welcome")}</div>

          <Grid container spacing={3}>
            <Grid item md={4}>
              <div className="step mb-2">
                <div className="circle">1</div>
              </div>
              <div className="text-center step-title">
                {t("affiliates.step1-title")}
              </div>
              <div className="mt-3">{t("affiliates.step1")}</div>
            </Grid>
            <Grid item md={4}>
              <div className="step mb-2">
                <div className="circle">2</div>
              </div>
              <div className="text-center step-title">
                {t("affiliates.step2-title")}
              </div>
              <div className="mt-3">{t("affiliates.step2")}</div>
            </Grid>
            <Grid item md={4}>
              <div className="step mb-2">
                <div className="circle">3</div>
              </div>
              <div className="text-center step-title">
                {t("affiliates.step3-title")}
              </div>
              <div className="mt-3">{t("affiliates.step3")}</div>
            </Grid>
          </Grid>

          <div className="content-creator my-5">
            <img
              className="img-fluid"
              src={contentCreator2}
              alt="Content creator"
              loading="lazy"
            />
          </div>

          <h3 className="mb-4">{t("affiliates.faq-title")}</h3>

          <Grid container spacing={3}>
            <Grid item md={6}>
              <div className="faq-title mb-3">{t("affiliates.faq1-title")}</div>
              <div>{t("affiliates.faq1-content")}</div>
            </Grid>
            <Grid item md={6}>
              <div className="faq-title mb-3">{t("affiliates.faq2-title")}</div>
              <div>{t("affiliates.faq2-content")}</div>
            </Grid>
          </Grid>
          <Grid container spacing={3} className="mt-4">
            <Grid item md={6}>
              <div className="faq-title mb-3">{t("affiliates.faq3-title")}</div>
              <div>{t("affiliates.faq3-content")}</div>
            </Grid>
            <Grid item md={6}>
              <div className="faq-title mb-3">{t("affiliates.faq4-title")}</div>
              <div>
                {t("affiliates.faq4-content")}{" "}
                <a href="https://app.lanchaslagaviota.com">
                  {t("affiliates.faq4-content-2")}
                </a>
              </div>
            </Grid>
          </Grid>
        </section>
      </Container>
    </Page>
  );
};

export default AffiliatesPage;
